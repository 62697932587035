<template>
  
  <div>
       <div class="header">
             <ul class="left_menu">
                <div class="item"> 
                   
                    <li class=" active a">时间管理</li>
                
                </div>
                 <div  class="item">  
                          <li class="a" ><router-link to="/index7/xiaoxi" style="display:flex;    align-items: center">  <i class=" el-icon-message-solid" style="color:#0034FF;width:25px;height:25px;border-radius:100%;background: #f5f5f5;text-align:center;line-height:25px;margin-right:5px"></i> <p style="width:15px;height:15px;background:red;text-align:center;font-size:12px;line-height:15px;border-radius:100%;color:#fff;margin-left:-10px;margin-top:-3px" v-if='result !==0' >{{result}}</p> 消息通知</router-link> </li>
                     <li style="cursor:pointer;" @click="dialogFormVisible = true">代理商<i class="el-icon-caret-bottom"></i>  </li>
                </div>    

                      <el-dialog class="kk" title="个人信息" style="text-align:center" :visible.sync="dialogFormVisible">
                        <el-form :model="info">
                            <el-form-item label="昵称" :label-width="formLabelWidth">
                            <el-input v-model="info.name" autocomplete="off">  <el-button slot="append" @click="a11()">修改</el-button></el-input>
                         
                            </el-form-item>
                            <el-form-item label="登录账号" :label-width="formLabelWidth">
                             <el-input v-model="info.phone" autocomplete="off"  :disabled="true"></el-input>
                            </el-form-item>
                             <el-form-item label="登录密码" :label-width="formLabelWidth">
                             <el-input v-model="password3" placeholder="输入旧密码"  autocomplete="off">  <el-button slot="append"  @click="a22()">修改</el-button></el-input>
                            </el-form-item>
                            <el-form-item label="新密码" v-show="password2" :label-width="formLabelWidth">
                             <el-input v-model="password" autocomplete="off">  <el-button slot="append"  @click="a33()">确认修改</el-button> </el-input>
                            </el-form-item>
                        </el-form>
                        <div slot="footer" class="dialog-footer">
                             <el-button type="primary" @click="tuichu" style="width:100%">退出登录</el-button>
                        </div>
                        </el-dialog>  
             </ul>
        </div>
       
            <div class="content" >
                 <div class="search" style=" display:flex;padding:10px 0px;align-items: center;
    justify-content: space-between;">
             <p>时间管理</p>
             

         </div>
                     <el-table
                    :border="true"
                    :data="datal2"
                :header-cell-style="{background:'#FAFAFA'}"
                    style="width: 100%;">
                        <el-table-column
                    :show-overflow-tooltip="true" 
                
                    prop="date"
                    label="日期"
                    >
                    </el-table-column>

                          <el-table-column
                    :show-overflow-tooltip="true" 
                
                    prop="time"
                    label="时间"
                    >
                    </el-table-column>
                   
                           <el-table-column
                    :show-overflow-tooltip="true" 
                
                  
                    label="是否禁用"
                    >
                      <template slot-scope="scope" >
                          <p>{{scope.row.isactive|capitalize}}</p>
                      </template>
                    </el-table-column>
                 
                 


                    <el-table-column     
                    label="操作" 
                        :show-overflow-tooltip="true" 
                    >
                    <template slot-scope="scope" >
              
                 <el-button
                        size="mini"
                        
                            type="danger"
                          v-if="scope.row.isactive=='Y'"
                        @click="find(scope.$index, scope.row)">禁用</el-button>

                          <el-button
                        size="mini"
                            type="primary"
                          v-if="scope.row.isactive!=='Y'"
                        @click="find1(scope.$index, scope.row)">启用</el-button>
                    </template>
                    </el-table-column>
                     
                  
               


             
                </el-table>
                    
                 
            </div>
  </div>
</template>

<script>
export default {
      filters: {
  capitalize: function (value) {
  if(value== 'Y'){
      return '正常'
    
  }else {
      return '禁用'
  }
  }
},
 data(){
        return{
            dialogFormVisible33:false,
            producImg:'',
            datal1:[],
                datal2:'',
            datall:[],
               form3: {
           name: '',
          phone:'',
          region: '',
          key_2:'',
          id:'',
          worktime:'',
       
        },
                dialogFormVisible:false, form: {
          name: '',
          region: '',
          date1: '',
          date2: '',
      
          delivery: false,
          type: [],
          resource: '',
          desc: ''
        },
        formLabelWidth: '120px',
        info:[],
infos:[],
result:'',
            activeName: 'first',
    
                 tableData: [],
                    data1:[],
                    data2:[],
                 password:'',
                   password2:false,
                  password3:''
        }
        },
       
       methods:{
                changeImage(e,a) {
                
            var file = e.target.files[0]
           
           let data = new FormData();
             this.$axios.get("/s/backend/qn/gettoken", {
                        params: { 'accessToken':localStorage.getItem('token'),
                         filename :file.name
                     }
                        })

                    .then(res=>{
                  
                data.append('token', res.data.uptoken);     
         
                data.append('file', e.target.files[0]);
                data.append('key', e.target.files[0].name);
              
                                                this.$axios({
                                        method: 'post',
                                        url: 'https://upload-z2.qiniup.com/',
                                        data,
                                        headers: {
                                            'Content-Type': 'multipart/form-data'
                                        }
                                    })
                                    .then(res => {
                                        this.producImg = "http://gqtlgj.cn/" + res.data.key;
                                       
                                    })
                                    .catch(err => {
                                      
                                    })

                  
                        })
                        .catch(function(){
                        
                        })

             },
           tj(){
               var that =this
               console.log(that.producImg)
                 this.$axios({
                        method: "post",
                        url: "/s/backend/index/add_lbt",
                        params: {
                        'accessToken' : localStorage.getItem('token'),
                        
                          
                        },
                        data:{
                             'imageurl':that.producImg,
                              "redirect_url": "string"
                        }
                        }) .then(res=>{
                            this.dialogFormVisible33= false
                             this.$axios.get("/s/backend/index/get_lbt", {
　  　params: { 'accessToken':localStorage.getItem('token')}
})
  .then(res=>{
       this.datal2 = res.data.data
          
        })
        .catch(function(){
           
        })  
                        })
           },
           find(index,row){
      
                this.$axios({
                        method: "post",
                        url: "/s/backend/index/service_appoint_time_change",
                        params: {
                        'accessToken' : localStorage.getItem('token'),
                        
                          
                        },
                        data:{
                            "date":row.date,
                            "oper": "ban",
                            "time": row.time,
                            "type": "tuning"
                        }
                        }) .then(res=>{
                             
this.$axios.get("/s/backend/index/service_appoint_time_list", {
　  　params: { 'accessToken':localStorage.getItem('token'),
'type':'tuning'}
})
  .then(res=>{
         this.datal2 = res.data.data
          
        })
        .catch(function(){
           
        })   
                        })


 
                      



    
           },





             find1(index,row){
      
                this.$axios({
                        method: "post",
                        url: "/s/backend/index/service_appoint_time_change",
                        params: {
                        'accessToken' : localStorage.getItem('token'),
                        
                          
                        },
                        data:{
                            "date":row.date,
                            "oper": "active",
                            "time": row.time,
                            "type": "tuning"
                        }
                        }) .then(res=>{
                             
this.$axios.get("/s/backend/index/service_appoint_time_list", {
　  　params: { 'accessToken':localStorage.getItem('token'),
'type':'tuning'}
})
  .then(res=>{
          this.datal2 = res.data.data
          
        })
        .catch(function(){
           
        })   
                        })
    
           },
           handleClick(){},
       
         a11(){
               

                  this.$confirm('此操作将修改昵称, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.$message({
            type: 'success',
            message: '修改成功!'
          });
            this.$axios({
                        method: "post",
                        url: "/s/backend/api/userupdate",
                        params: {
                        accessToken:localStorage.getItem('token'),
                   
                        },
                        data:{
                            "jobnumber": localStorage.getItem('jobnumber'),
                            "name": this.info.name
                        }
                        }) .then(res=>{
                        
                           
                          var cardNumObj = { arealist:res.data.data }
                           var cardNumObj1 = { yyzcurl:'' }
                          var assignObj =  Object.assign(this.info,cardNumObj);
                          var assignObj1 = Object.assign(assignObj,cardNumObj1)
                                        this.$axios({
                                        method: "post",
                                        url: "/s/backend/api/userupdate",
                                        params: {
                                        accessToken : localStorage.getItem('token')
                                        },
                                        data:assignObj1,
                                        }) .then(res=>{
                                            this.dialogFormVisible = false
                                            this.$message({
                                message:'修改成功',
                                type: 'success'
                                    });
                        })
        .catch(function(){
           
        })  
                  
                        })
        .catch(function(){
           
        })  


        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消修改'
          });          
        });
      



 

      
       
        },
        a22(){
            this.password2=true
         
              
        },
        a33(){

              this.$confirm('此操作将修改密码, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
         
               if(this.password ==''){
                this.$message({
                   message:'请输入新密码后点击修改',
                  type: 'success'
                     }); 
            }else{
                    this.$axios({
                            method: "post",
                            url: "/s/backend/api/changepass",
                            params: {
                            accessToken : localStorage.getItem('token')
                            },
                            data:{
                        "jobnumber":  localStorage.getItem('jobnumber'),
                        "newpassword": this.password,
                        "oldpassword": this.password3
                        },
                            }) .then(res=>{
                            
                            if(res.data.code == 0){
                                    this.dialogFormVisible = false
                                    this.$message({
                                message:'修改成功',
                                type: 'success'
                                    });
                            }else{
                                    this.dialogFormVisible = false
                                    this.$message({
                                        message:res.data.msg,
                                        type: 'success'
                                            });
                            }
                            })
            .catch(function(){
            
            })  
            }

        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消修改'
          });          
        });







            
        },
          tuichu(){
            this.dialogFormVisible = false;
            window.localStorage.removeItem('token')
                     this.$router.push({ name: 'login'})
        },
        a33(){
               if(this.password ==''){
                this.$message({
                   message:'请输入新密码后点击修改',
                  type: 'success'
                     }); 
            }else{
                    this.$axios({
                            method: "post",
                            url: "/s/backend/api/changepass",
                            params: {
                            accessToken : localStorage.getItem('token')
                            },
                            data:{
                        "jobnumber":  localStorage.getItem('jobnumber'),
                        "newpassword": this.password,
                        "oldpassword": this.password3
                        },
                            }) .then(res=>{
                            
                            if(res.data.code == 0){
                                    this.dialogFormVisible = false
                                    this.$message({
                    message:'修改成功',
                    type: 'success'
                        });
                            }else{
                                        this.$message({
                    message:res.data.msg,
                    type: 'success'
                        });
                            }
                            })
            .catch(function(){
            
            })  
            }
        },
               
       },
        mounted(){
           
this.$axios.get("/s/backend/message/get_message_unread", {
　  　params: { 'accessToken':localStorage.getItem('token')}
})
  .then(res=>{
         this.result = res.data.result
          
        })
        .catch(function(){
           
        })               
            

this.$axios.get("/s/backend/index/service_appoint_time_list", {
　  　params: { 'accessToken':localStorage.getItem('token'),
'type':'tuning'}
})
  .then(res=>{
         this.datal2 = res.data.data
          
        })
        .catch(function(){
           
        })   
 
    
  





         
  

               this.$axios.get("/s/backend/api/user_detail", {
        params: { 'accessToken':localStorage.getItem('token'),
        'jobnumber':localStorage.getItem('jobnumber')
        }
        })

     .then(res=>{
          this.info = res.data.data
           
        })
        .catch(function(){
           
        })
        }
}
</script>

<style scoped>

/deep/.el-dialog__wrapper{
    top:-50px
}

/deep/.kk .el-dialog{
        width: 25%;

    float: right;
  
}


/deep/ .el-dialog{
        width:30%;


  
}
    .content{
        background: #f5f5f5;
        padding:10px 40px;
    }
 .content .content_ul{
            display: flex;
            justify-content: space-between;
        }
         .content .content_ul li{
             width: 32%;padding-left: 15px;
         }




.item li:hover{
    color: #0034FF;
}
.item li a:hover{
    color: #0034FF !important;
}
   /deep/.el-table th > .cell {
      text-align: center;

    }

    /deep/.el-table .cell {
      text-align: center;

    }

/deep/.el-tabs--card>.el-tabs__header{
    border-bottom: none !important;
}

/deep/ .el-tabs--card>.el-tabs__header .el-tabs__item{
    border-bottom:1px solid #E4E7ED;}
/deep/ .el-tabs__item.is-active{
    border:1px solid #409EFF;
    border-left:1px solid #409EFF !important;
      border-bottom:1px solid #409EFF !important;
}
    .header{
            min-height: 57px;
        display: flex;
        align-items: center;
        width: 100%;
        justify-content: space-between;
    }
    .header .left_menu{
        display: flex;   width: 100%;  padding:0 40px;
        justify-content: space-between;
    }
    .header .left_menu .item{
        display: flex;
        align-items: center;
    }
        .header .left_menu .item .a{
            margin-right: 60px;  
            cursor: pointer;
        }
           .header .left_menu .item li a{
               text-decoration: none;color: #000;font-size: 16px;
font-weight: 500;
           }

        .header .active{
            color: #0034FF;
            position:relative;
        }
             .header .active::before{
                      content: "";
   
         left: 0;
    position: absolute;
    bottom:-120%;
    height:3px;
    width:30px;
    background: #0034FF;
            }
</style>